import { qmService } from 'api/services';
import { QMIdentifiers, QMUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';

const fetchQuestionnaire = (questionnaireType: string) =>
  qmService.get(QMUrls.questionnaire(questionnaireType));

export const useQMQuestionnaire = (questionnaireType: string) =>
  useQuery([QMIdentifiers.questionnaire, questionnaireType], () =>
    fetchQuestionnaire(questionnaireType)
  );
