import { QMScoreResponse, ScoreResponse } from 'api/saq/types';
import { qmScoringService } from 'api/services';
import { QMIdentifiers, QMScoringUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';

const fetchScore = (questionnaireType: string): Promise<QMScoreResponse> =>
  qmScoringService.get(QMScoringUrls.score(questionnaireType));

export const useQMScore = (questionnaireId: string, questionnaireType: string) =>
  useQuery<ScoreResponse, Error, ScoreResponse, string[]>(
    [QMIdentifiers.score, questionnaireId, questionnaireType],
    async () => {
      const data: QMScoreResponse = await fetchScore(questionnaireType);
      return {
        ...data,
        results: data.results.map((score) => ({
          ...score,
          questionnaire: score.module
        }))
      };
    },
    {
      enabled: !!questionnaireId
    }
  );
