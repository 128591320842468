export enum LocalStorageItems {
  // Auth
  AuthenticationStatusKey = 'authenticated',
  StateKey = 'state',
  TokenKey = 'token'
}

export const TRUE_VALUE = 'true';

class LocalStorage {
  setItem(key: LocalStorageItems, value: string) {
    window.localStorage.setItem(key, value);
  }

  getItem(key: LocalStorageItems) {
    return window.localStorage.getItem(key);
  }

  removeItem(key: LocalStorageItems) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}

const localStorageClass = new LocalStorage();
export default localStorageClass;
