import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAddFeature } from 'api/userFeatures/hooks/mutations';
import { Layout } from 'layout/Layout';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const AddFeature = () => {
  const history = useHistory();
  const styles = useStyles();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const addMutation = useAddFeature();
  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    addMutation.mutate(
      { name, description, default: false },
      {
        onSuccess: () => history.push('/admin/features')
      }
    );
  };
  return (
    <Layout title="Add Feature">
      <Box className={styles.container}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          className={styles.form}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="description"
            label="Description"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={addMutation.isLoading}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%'
  }
}));
