import {userService} from "api/services";
import {userFeaturesUrls} from "api/userFeatures/resourcesUrl";
import {useMutation} from "react-query";
import {FeatureResponse, UserFeatureIdentifiers} from "api/userFeatures/types";
import {queryClient} from "services/reactQuery/client";

const deleteFeature = (featureId: string) => {
    return userService.delete(userFeaturesUrls.deleteFeature(featureId));
};

export const useDeleteFeature = (featureId: string) => {
    return useMutation(UserFeatureIdentifiers.DeleteFeature, deleteFeature, {
        onSuccess: (data, featureId) => {
            queryClient.setQueryData<FeatureResponse>(
                [UserFeatureIdentifiers.FeaturesList],
                (old) => {
                    const previousFeatures = old?.results || [];
                    return {
                        links: old?.links || {},
                        results: previousFeatures.filter(
                            (feature) => feature.id !== featureId
                        )
                    };
                }
            );
        }
    });
};
