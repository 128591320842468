import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteConfig } from './RouteConfig';
import { AdminLayout } from 'atoms/layouts/AdminLayout';
import { LandingLayout } from 'atoms/layouts/LandingLayout';
import { AuthCallback } from 'services/auth/AuthCallback';
import { RequireAuth } from 'services/auth/RequireAuth';
import { PATHS } from 'consts/paths';

type Props = {};

export const HomeRoutes: RouteConfig[] = [
  {
    path: '/',
    component: LandingLayout
  },
  {
    path: '/admin',
    component: AdminLayout,
    requireAuth: true
  }
];

class HomeRouter extends React.Component<Props> {
  static routes = HomeRoutes;
  render() {
    return (
      <Switch>
        {HomeRoutes.map((route) => (
          <Route exact={route.path === '/'} key={route.path} path={route.path}>
            {route && route.requireAuth ? (
              <RequireAuth>
                <route.component />
              </RequireAuth>
            ) : (
              <route.component />
            )}
          </Route>
        ))}
        <Route path={PATHS.LoginCallback}>
          <AuthCallback />
        </Route>
      </Switch>
    );
  }
}

export default HomeRouter;
