import { Link } from 'api/userFeatures/types';

export const userFeaturesUrls = {
  featureByUserCode: (userCode: string | undefined) => `/features/user/sedexCode/${userCode}`,
  toggleFeaturesForUser: '/features/user',
  features: '/features',
  updateFeature: (featureId: string) => `/features/${featureId}`,
  deleteFeature: (featureId: string) => `/features/${featureId}`,
  addFeature: '/features',
  anyPath: (link: Link) => link.href
};
