import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RoleFeature, AnyListResponse, Link } from 'api/userFeatures/types';
import { useAnyLinkableQuery } from 'api/userFeatures/hooks/queries';

type RoleFeatureTableProps = {
  roleFeaturesLink: Link;
};

export const RoleFeatureTable: React.FC<RoleFeatureTableProps> = ({
  roleFeaturesLink
}) => {
  const classes = useStyles();
  const { data: roleFeatures } =
    useAnyLinkableQuery<AnyListResponse<RoleFeature>>(roleFeaturesLink);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>Role Name</TableCell>
          <TableCell className={classes.tableHeading}>Member Types</TableCell>
          <TableCell className={classes.tableHeading}>Enabled</TableCell>
          <TableCell className={classes.tableHeading}>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {roleFeatures &&
          roleFeatures.results &&
          roleFeatures.results.map((roleFeature: RoleFeature) => (
            <TableRow>
              <TableCell component="th" scope="row">
                {roleFeature.roleName}
              </TableCell>
              <TableCell component="th" scope="row">
                {roleFeature.memberTypes.map((memberType) => memberType + ', ')}
              </TableCell>
              <TableCell component="th" scope="row">
                {'Enabled:  ' + roleFeature.feature.enabled}
              </TableCell>

              <TableCell component="th" scope="row">
                {roleFeature.createdTime}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  }
}));
