import { styled, TableCell, TableRow, TextField } from '@mui/material';

export type TableRowProps = {
  showDelimiter?: boolean;
};

export type TableCellProps = {
  isWide?: boolean;
  isEditable?: boolean;
};

export const TableHeadCell = styled(TableCell)(() => ({
  fontWeight: 'bold'
}));

export const MCSectionContainer = styled('div')(() => ({
  marginTop: '28px'
}));

export const TableContainer = styled('div')(() => ({
  marginTop: '50px'
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => prop !== 'showDelimiter'
})<TableRowProps>(({ showDelimiter }) => ({
  '> .MuiTableCell-root': {
    borderTop: showDelimiter ? '1px solid black' : ''
  }
}));

export const EditBox = styled(TextField)(() => ({
  width: '25px',
  '& .MuiInputBase-input': {
    textAlign: 'center',
    padding: '1px'
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  'input[type=number]': {
    '-moz-appearance': 'textfield'
  }
}));

export const TableCellWithBorder = styled(TableCell, {
  shouldForwardProp: (prop: string) => !['isWide', 'isEditable'].includes(prop)
})<TableCellProps>(({ isWide = false, isEditable = false }) => ({
  borderBottom: '2px solid black',
  maxWidth: isWide ? '300px' : '',
  wordWrap: 'break-word',
  cursor: isEditable ? 'pointer' : 'default'
}));

export const StyledCell = styled(TableCell)(() => ({
  cursor: 'pointer'
}));
