import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Feature, FeatureLinkRelationships, Link } from 'api/userFeatures/types';
import { useDeleteFeature } from 'api/userFeatures/hooks/mutations';
import { useAnyLinkableQuery } from 'api/userFeatures/hooks/queries';
import { Spinner } from 'atoms/spinner/Spinner';
import { Layout } from 'layout/Layout';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { OrgFeatureTable } from '../org/OrgFeatureTable';
import { RoleFeatureTable } from '../role/RoleFeatureTable';
import { UserFeatureTable } from '../user/UserFeatureTable';

type LocationParams = {
  link: Link;
};

export const FeatureDetail = () => {
  const history = useHistory();
  const location = useLocation<LocationParams>();
  const styles = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { data: feature, isLoading } = useAnyLinkableQuery<Feature>(
    location.state.link
  );
  const deleteMutation = useDeleteFeature(feature?.id || '');
  const userFeatureLink =
    feature && feature.links[FeatureLinkRelationships.userFeatures];
  const orgFeatureLink =
    feature && feature.links[FeatureLinkRelationships.orgFeatures];
  const roleFeatureLink =
    feature && feature.links[FeatureLinkRelationships.roleFeatures];

  const handleDelete = () => {
    deleteMutation.mutate(feature?.id || '', {
      onSuccess: () => history.push('/admin/features')
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="space-around"
        alignItems="stretch"
        sx={{ padding: 2 }}
      >
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Feature : {feature?.name}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={styles.errorButton}
              onClick={() => setOpen(true)}
              disabled={deleteMutation.isLoading}
            >
              Delete feature
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            Description: {feature?.description}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            Created : {feature?.createdTime}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">User Level</Typography>
          {userFeatureLink && (
            <UserFeatureTable userFeaturesLink={userFeatureLink} />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h6">Org Level</Typography>
          {orgFeatureLink && (
            <OrgFeatureTable orgFeaturesLink={orgFeatureLink} />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h6">Role Level</Typography>
          {roleFeatureLink && (
            <RoleFeatureTable roleFeaturesLink={roleFeatureLink} />
          )}
        </Grid>
      </Grid>
      <Dialog open={open}>
        <DialogTitle>Delete Feature</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete <strong>{feature?.name}</strong>{' '}
          feature?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  errorButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));
