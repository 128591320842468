import React, { useEffect } from 'react';
import { useAuth } from './useAuth';

export const RequireAuth: React.FC = ({ children }) => {
  const { isAuthed, enforceSignIn } = useAuth();

  useEffect(() => {
    if (!isAuthed) {
      enforceSignIn();
    }
  }, [isAuthed, enforceSignIn]);

  if (!isAuthed) {
    return <></>;
  }

  return <>{children}</>;
};
