import { EditBox, StyledCell } from '../../screens/saq/SaqRisksTable.styles';
import { InputBaseComponentProps } from '@mui/material';
import React from 'react';
import { Typography } from '@material-ui/core';

const numberInputProps = {
  type: 'number',
  inputMode: 'numeric',
  pattern: '[0-9]*',
  min: 0,
  max: 100
} as InputBaseComponentProps;

type CellProps = {
  questionCode: string;
  value?: number;
  label?: string;
  isEditable: boolean;
  isError?: boolean;
  onShow: Function;
  onChange: Function;
  rowSpan?: number;
};

export const EditCell = ({
  questionCode,
  label,
  value,
  isEditable,
  isError = false,
  onShow,
  onChange,
  rowSpan
}: CellProps) => {
  return (
    <StyledCell
      align="center"
      size="small"
      scope="row"
      onClick={() => onShow(questionCode)}
      rowSpan={rowSpan}
    >
      {isEditable ? (
        <EditBox
          placeholder="-"
          inputProps={numberInputProps}
          defaultValue={value || value === 0 ? value : ''}
          error={isError}
          onChange={(event) => onChange(event.target.value, label)}
        />
      ) : (
        <Typography>
          {value || value === 0 ? value : '-'}
        </Typography>
      )}
    </StyledCell>
  );
};
