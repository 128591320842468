import { QScore, ScoreUpdate } from 'api/saq/types';
import { qmScoringService } from 'api/services';
import { QMIdentifiers, QMScoringUrls } from 'api/saq/resourcesUrl';
import { useMutation } from 'react-query';
import { convertToPayload } from './utils';

const updateScores = (payload: ScoreUpdate) =>
  qmScoringService.put(QMScoringUrls.updateScores(payload.scoreId), payload.scores);

export const useUpdateQMQuestionScores = () => {
  return useMutation(
    QMIdentifiers.updateScores,
    (questionScore: QScore) => updateScores(convertToPayload(questionScore)),
    {
      onError: () => {
        alert('Unable to update scores');
      }
    }
  );
};
