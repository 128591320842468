import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OrgFeature, AnyListResponse, Link } from 'api/userFeatures/types';
import { useAnyLinkableQuery } from 'api/userFeatures/hooks/queries'

type OrgFeatureTableProps = {
  orgFeaturesLink: Link;
};

export const OrgFeatureTable: React.FC<OrgFeatureTableProps> = ({
  orgFeaturesLink
}) => {
  const classes = useStyles();
  const { data: orgFeatures } =
    useAnyLinkableQuery<AnyListResponse<OrgFeature>>(orgFeaturesLink);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>Org Code</TableCell>
          <TableCell className={classes.tableHeading}>Role Name</TableCell>
          <TableCell className={classes.tableHeading}>Enabled</TableCell>
          <TableCell className={classes.tableHeading}>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orgFeatures &&
          orgFeatures.results &&
          orgFeatures.results.map((orgFeature: OrgFeature) => (
            <TableRow>
              <TableCell component="th" scope="row">
                {orgFeature.orgCode}
              </TableCell>
              <TableCell component="th" scope="row">
                {orgFeature.roleName}
              </TableCell>
              <TableCell component="th" scope="row">
                {'Enabled:  ' + orgFeature.feature.enabled}
              </TableCell>

              <TableCell component="th" scope="row">
                {orgFeature.createdTime}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  }
}));
