import {useQuery} from 'react-query';
import {UserFeatureIdentifiers, UserFeatureResponse} from 'api/userFeatures/types';
import {userService} from 'api/services';
import {userFeaturesUrls} from 'api/userFeatures/resourcesUrl';

export const useFeatureQuery = (userCode: string | undefined) =>
    useQuery<UserFeatureResponse>(
        [UserFeatureIdentifiers.FeaturesByUser, userCode],
        () => userService.get(userFeaturesUrls.featureByUserCode(userCode)),
        {
            enabled: !!userCode,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchInterval: false
        }
    );
