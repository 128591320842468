import {questionnaireService} from "api/services";

type DownloadedFileInfo = {
    download: (fileName: string, fileDownloadLink: string) => Promise<void>;
};

export const useDownloadFile = (): DownloadedFileInfo => {
    const download = async (fileName: string, fileDownloadLink: string) => {
        try {
            const result = await questionnaireService.get(fileDownloadLink, {responseType: "blob"});
            const blob = new Blob([result as BlobPart]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            document.body.appendChild(link);

            link.href = url;
            link.download = fileName;
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }, 0)
        } catch (error) {
            console.log('File download failed');
        }
    }

    return { download };
};
