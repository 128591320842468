import * as React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router';
import hero from './../../../assets/jpgs/hero.jpg';
import { PATHS } from 'consts/paths';

export const Welcome: React.FC = () => {
  const classes = useStyles();
  //myRef: React.RefObject<HTMLDivElement>
  const history = useHistory();

  const gotoAdmin = () => {
    history.push(PATHS.Admin);
  };

  return (
    <div className={classes.root}>
      <section
        className={classes.hero}
        style={{ paddingBottom: '30%', backgroundImage: `url(${hero})` }}
      >
        <span />
        <div className={classes.heroContent}>
          <Grid container spacing={3} className={classes.heroGrid}>
            <div className="js-Hero">
              <h1>Sedex Admin Console</h1>
              <Button
                type="submit"
                name="submit"
                onClick={gotoAdmin}
                color="primary"
              >
                Login
              </Button>
              <p />
              <Typography variant="subtitle1">
                Empowering Responsible Supply Chains.
              </Typography>
              <p />
            </div>
          </Grid>
        </div>
      </section>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '80px 24px;'
  },
  hero: {
    position: 'relative',
    paddingTop: 150,
    backgroundSize: 'cover',
    paddingBottom: '25%',
    backgroundPosition: 'center'
  },
  heroContent: {
    display: 'flex',
    position: 'absolute',
    paddingTop: 64,
    paddingLeft: 24,
    paddingRight: 24
  },
  heroGrid: {
    alignContent: 'center'
  }
}));
