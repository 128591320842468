import {useQuery} from "react-query";
import {WorkplaceRiskScoresResult} from "api/saq/types";
import {SaqIdentifiers, ScoringUrls} from "api/saq/resourcesUrl";
import {scoringService} from "api/services";

const fetchWorkplaceRiskScores = ({
  queryKey
}: {
    queryKey: string[];
}): Promise<WorkplaceRiskScoresResult> => {
    const [, siteCode] = queryKey;
    return scoringService.get(ScoringUrls.workplaceRiskScores(siteCode));
};

export const useWorkplaceRiskScores = (siteCode: string) =>
    useQuery<
        WorkplaceRiskScoresResult,
        Error,
        WorkplaceRiskScoresResult,
        string[]
        >([SaqIdentifiers.workplaceRiskScores, siteCode], fetchWorkplaceRiskScores, {
        enabled: !!siteCode
    });
