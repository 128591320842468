import { QScore, Section, SubCategoryType } from 'api/saq/types';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import { ScoresTable } from '../../../components/ScoresTable/ScoresTable';
import { MCSectionContainer } from '../SaqRisksTable.styles';
import { Spinner } from '../../../atoms/spinner/Spinner';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { useGetQuestionScoresData } from '../hooks/useQuestionScoresData';
import { Typography } from '@mui/material';

export const ManagementControls = () => {
  const {
    strategy: {
      [SubCategoryType.ManagementControls]: { columnsNames, columnsMeta }
    }
  } = useQuestionnaireContext();
  const { questionnaire, isLoading } = useGetQuestionScoresData(
    SubCategoryType.ManagementControls
  );

  const managementControlColumnNames = columnsMeta
    .map((col) => col.name)
    .concat(columnsNames);

  return (
    <>
      <QuestionnaireTypeSelector />
      {isLoading ? (
        <Spinner isSuspense />
      ) : (
        <>
          <Typography variant="h6">
            Management Controls - question scores
          </Typography>
          {questionnaire.sections
            .filter((section) => section.questions.length > 0)
            .map((section: Section<QScore>) => (
              <MCSectionContainer key={`section-${section.id}`}>
                <ScoresTable
                  title={section.label}
                  headerNames={managementControlColumnNames}
                  columnsMeta={columnsMeta}
                  sections={[section]}
                />
              </MCSectionContainer>
            ))}
        </>
      )}
    </>
  );
};
