import { FeatureToggle } from '../../api/userFeatures/types';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface FeatureTableProps {
  features: FeatureToggle[];
  onFeatureToggle: (feature: FeatureToggle) => void;
}

export const FeatureTable: React.FC<FeatureTableProps> = ({
  features,
  onFeatureToggle
}) => {
  const classes = useStyles();

  // Have impersonate disabled by default as its a read only feature
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>Feature Name</TableCell>
          <TableCell className={classes.tableHeading}>Feature Type</TableCell>
          <TableCell align="right" className={classes.tableHeading}>
            Enabled
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {features.map((feature: FeatureToggle) => (
          <TableRow
            key={feature.name}
            className={
              feature.name === 'Impersonation' ? classes.disabled : undefined
            }
          >
            <TableCell component="th" scope="row">
              {feature.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {feature.type}
            </TableCell>
            <TableCell align="right">
              <Checkbox
                checked={feature.enabled}
                disabled={feature.name === 'Impersonation'}
                onChange={() =>
                  onFeatureToggle({ ...feature, enabled: !feature.enabled })
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  }
}));
