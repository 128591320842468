import {
  Answer,
  AScore,
  Band,
  BandAScore,
  BasicAnswer,
  BasicAScore,
  CalculatedQuestionResult,
  CompanySizeScoreType,
  QuestionResult,
  Score,
  ScorePossibleAnswer,
  ScoreResult,
  UpdateAnswerScore
} from 'api/saq/types';

export function extractScoreByQuestion(
  questionCode: string,
  scores?: ScoreResult[]
) {
  return scores?.find((score) => score.questionCode === questionCode);
}

function extractCompanySizeScores(
  answerCode: string,
  scores?: ScorePossibleAnswer[]
) {
  const score = scores?.find((score) => score.code === answerCode);
  return score?.scores;
}

export function mapAnswersWithScores(
  questionResult: QuestionResult | undefined,
  score: UpdateAnswerScore | undefined,
  scoreTypes: CompanySizeScoreType[]
) {
  const emptyScores = scoreTypes.map((key) => {
    return { label: key } as Score;
  });
  switch (score?.type) {
    case 'choice':
      return mapChoiceTypeScores(
        questionResult?.possibleAnswers,
        score.possibleAnswers,
        scoreTypes,
        emptyScores
      );
    case 'banded':
      return mapBandedTypeScores(score.bands, scoreTypes, emptyScores);
    case 'basic':
      return mapBasicTypeScores(score.basicAnswer, scoreTypes, emptyScores);
    default:
      return mapAnswer(questionResult?.possibleAnswers, emptyScores);
  }
}

function mapBasicTypeScores(
  basic: BasicAnswer | undefined,
  scoresTypes: CompanySizeScoreType[],
  emptyScores: Score[]
) {
  return (
    basic &&
    Object.keys(basic).map((answerKey) => {
      return {
        id: answerKey,
        label: answerKey,
        type: answerKey,
        scores:
          scoresTypes.map((key) => {
            return {
              label: key,
              value: basic?.[answerKey as keyof BasicAnswer]?.[key]
            } as Score;
          }) || emptyScores
      } as BasicAScore;
    })
  );
}

function mapAnswer(
  possibleAnswers: Answer[] | undefined,
  emptyScores: Score[]
) {
  if (possibleAnswers === undefined) {
    return [
      {
        id: 'not implemented',
        label: 'not implemented',
        scores: emptyScores
      } as AScore
    ];
  }
  return possibleAnswers?.map((answer) => {
    return {
      id: answer.id,
      label: answer.label,
      scores: emptyScores
    } as AScore;
  });
}

function mapChoiceTypeScores(
  answers: Answer[] | undefined,
  scores: ScorePossibleAnswer[] | undefined,
  scoresTypes: CompanySizeScoreType[],
  emptyScores: Score[]
) {
  return answers?.map((answer) => {
    const companySizeScores = extractCompanySizeScores(answer.id, scores);
    return {
      ...answer,
      scores:
        scoresTypes.map((key) => {
          return {
            label: key,
            value: companySizeScores?.[key]
          } as Score;
        }) || emptyScores
    } as AScore;
  });
}

function mapBandedTypeScores(
  bands: Band[] | undefined,
  scoresTypes: CompanySizeScoreType[],
  emptyScores: Score[]
) {
  return bands?.map((band) => {
    const companySizeScores = band.scores;

    const label =
      band.to === 0
        ? 0
        : `from ${band.from ? band.from : 0}${band.to ? ` to ${band.to}` : ''}`;

    return {
      id: `from-${band.from ? band.from : 0}${band.to ? `-to-${band.to}` : ''}`,
      label,
      ...band,
      scores:
        scoresTypes.map((key) => {
          return {
            label: key,
            value: companySizeScores?.[key]
          } as Score;
        }) || emptyScores
    } as BandAScore;
  });
}

export function mapCalcQuestions(calcQuestions?: CalculatedQuestionResult[]) {
  return calcQuestions?.map((question) => {
    return {
      ...question,
      possibleAnswers: question.options?.map((option) => {
        return {
          id: option.key,
          label: option.label
        };
      })
    } as QuestionResult;
  });
}
