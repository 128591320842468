import {
  Answer,
  AnswerDataTypeName,
  AnswerItem,
  Answers,
  CalculatedRiskAnswersResult,
  ColumnType,
  FileAnswerValue,
  FileViewAnswerValue,
  MultiChoiceAnswerValue,
  QScore,
  QuestionResult,
  QuestionRiskData,
  Tag,
  ChoiceAnswerValue,
  ScoreResult,
  CompanySizeScoreType,
  Band,
  SectionsResponse
} from 'api/saq/types';
import { SaqUrls } from 'api/saq/resourcesUrl';
import { Workplace, WorkplaceActivityProfile } from './types';

export const getSubcategory = (tag?: Tag) => {
  switch (tag?.subCategory as string) {
    case 'LABOUR_STANDARDS_AND_RIGHTS':
      return 'LABOUR STANDARDS';
    case 'HEALTH_AND_SAFETY':
      return 'HEALTH AND SAFETY';
    case 'MANAGEMENT_CONTROLS':
      return 'MANAGEMENT CONTROLS';
    case 'SITE_CHARACTERISTICS':
      return 'SITECHARACTERISTICS';
    default:
      return '';
  }
};

export const getCategory = (
  questionScore: QScore,
  columnProperty: ColumnType
) => {
  switch (columnProperty) {
    case 'PILLAR':
      return getSubcategory(
        questionScore.tags.find(
          (tag) => tag.category === (columnProperty as string)
        )
      );
    case 'IS_SAQ':
      return !questionScore.id.startsWith('employee.') ? 'SAQ' : 'Workers Info';
    case 'QUESTION':
      return questionScore.label;
    case 'SUBSECTION_NAME':
      return questionScore.subsection;
    default:
      return 'Empty';
  }
};

const getAnswer = (
  possibleAnswers: Answer[],
  answerResponse: Answers | undefined,
  questionId: string
): string[] | FileViewAnswerValue[] => {
  const answerData = answerResponse?.answers.find(
    (answer) => answer.questionCode === questionId
  )?.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.MULTICHOICE: {
        const value = answerData.value as MultiChoiceAnswerValue[];
        return value.map((answer) => {
          const possibleAnswer = possibleAnswers.find(
            (a) => a.id === answer.answerCode
          );

          return possibleAnswer ? possibleAnswer.label : answer.value;
        });
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        return [value.toString()];
      case AnswerDataTypeName.FILE: {
        const value = answerData.value as FileAnswerValue[];
        return value.map((a) => ({
          fileName: a.fileName ?? '',
          fileLink: a.fileLink ?? '',
          fileDownloadLink: a.fileId
            ? SaqUrls.file(
                answerResponse.siteQuestionnaireId,
                answerResponse.sectionId,
                questionId,
                a.fileId
              )
            : ''
        }));
      }
      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};

const getAnswerScores = (
  answerResponse: Answers | undefined,
  questionId: string,
  scoreAnswer?: ScoreResult[],
  companySize?: CompanySizeScoreType
): any[] | string[] | FileViewAnswerValue[] => {
  const answerData = answerResponse?.answers.find(
    (answer) => answer.questionCode === questionId
  )?.answerData;

  const scoresData = scoreAnswer?.find(
    (score) => score.questionCode === questionId
  )?.scores?.possibleAnswers;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;

        const score =
          companySize &&
          scoresData?.find((s) => s.code === value)?.scores[companySize];

        return [score];
      }
      case AnswerDataTypeName.MULTICHOICE: {
        const value = answerData.value as MultiChoiceAnswerValue[];
        return value.map((answer) => {
          const score =
            companySize &&
            scoresData?.find((s) => s.code === answer.answerCode)?.scores[
              companySize
            ];

          return score;
        });
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        const score =
          companySize &&
          scoresData?.find((s) => s.code === value.toString())?.scores[
            companySize
          ];

        return [score];
      case AnswerDataTypeName.FILE: {
        const value = answerData.value as FileAnswerValue[];
        return value.map((a) => ({
          fileName: a.fileName ?? '',
          fileLink: a.fileLink ?? '',
          fileDownloadLink: a.fileId
            ? SaqUrls.file(
                answerResponse.siteQuestionnaireId,
                answerResponse.sectionId,
                questionId,
                a.fileId
              )
            : ''
        }));
      }
      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};
export const getQuestionRiskFromRecord = (
  record: Record<string, number> | undefined,
  allQuestions: QuestionResult[],
  allAnswers: Answers[],
  calculatedAnswers: CalculatedRiskAnswersResult | undefined,
  questionScores?: ScoreResult[],
  companySize?: CompanySizeScoreType,
  sectionData?: SectionsResponse
): QuestionRiskData[] => {
  const riskData = [];
  if (record) {
    for (const key in record) {
      const question = allQuestions?.find((question) => question.id === key);
      const answerResponse = allAnswers?.find(
        (answer: Answers) => answer.sectionId === question?.subsectionId
      );
      let calculatedAnswer: AnswerItem | undefined = undefined;

      if (!answerResponseHasAnswer(answerResponse, key)) {
        calculatedAnswer = calculatedAnswers?.answers.find(
          (answer) => answer.questionCode === key
        );
      }
      const questionScoreData = questionScores?.find(
        (score) => score.questionCode === key
      );

      const calculatedAnswerData =
        calculatedAnswer &&
        getCalculatedAnswer(
          question?.possibleAnswers ?? [],
          calculatedAnswer,
          questionScoreData
        );

      riskData.push({
        label: question?.label || key,
        tags: question?.tags ? question.tags.map((tag) => tag.subCategory) : [],
        pillars: question?.tags
          ? question.tags
              .filter((tag) => tag.category === 'PILLAR')
              .map(({ subCategory }) => subCategory)
          : [],
        sectionName: sectionData?.sections?.find((section) =>
          section.children.find((child) => child.id === question?.subsectionId)
        )?.name,
        score: record[key],
        answer: calculatedAnswer
          ? getCalculatedAnswer(
              question?.possibleAnswers ?? [],
              calculatedAnswer,
              questionScoreData
            )
          : getAnswer(question?.possibleAnswers ?? [], answerResponse, key),
        questionScore: questionScoreData?.scores?.maxScore,
        key: key,
        answerScore: calculatedAnswer
          ? getCalculatedAnswerScore(
              record[key],
              questionScoreData,
              calculatedAnswerData
            )
          : getAnswerScores(answerResponse, key, questionScores, companySize)
      });
    }
  }

  return riskData;
};

const answerResponseHasAnswer = (
  answerResponse: Answers | undefined,
  questionCode: string
): boolean => {
  return !!answerResponse?.answers?.find(
    (answer) => answer.questionCode === questionCode
  );
};

export const sortingScoreQuestions = (
  allQuestions: QuestionResult[],
  scoreQuestions: Record<string, number> | undefined
) => {
  if (scoreQuestions) {
    const sortedScoreQuestions: Record<string, number> = {};
    allQuestions.forEach((question) => {
      for (const key in scoreQuestions) {
        if (question.id === key) {
          sortedScoreQuestions[key] = scoreQuestions[key];
        }
      }
    });
    return sortedScoreQuestions;
  } else {
    return undefined;
  }
};

const getBandRange = (value: number, bands: Band[]) => {
  return bands?.reduce((acc, curr) => {
    if (curr.from !== undefined && value >= curr.from) {
      if (curr.to !== undefined && value <= curr.to) {
        return `from ${curr.from} to ${curr.to}`;
      }
      return `from ${curr.from}`;
    }

    return acc;
  }, '');
};

const getCalculatedAnswer = (
  possibleAnswers: Answer[],
  calculatedAnswer: AnswerItem,
  questionScore?: ScoreResult
): string[] => {
  const answerData = calculatedAnswer.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;

        if (questionScore?.scores.type === 'banded') {
          const band = getBandRange(value, questionScore.scores.bands || []);
          return band ? [value.toString(), band] : [value.toString()];
        }

        return [value.toString()];

      case AnswerDataTypeName.CHOICE:
        const { answerCode } = answerData.value as ChoiceAnswerValue;
        const answer =
          possibleAnswers.find(({ id }) => id === answerCode)?.label || '';
        return [answer];

      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};

const getCalculatedAnswerScore = (
  score: number,
  questionScoreData?: ScoreResult,
  calculatedAnswerData?: string[]
) => {
  if (
    questionScoreData?.scores.type === 'banded' &&
    calculatedAnswerData?.length &&
    calculatedAnswerData?.length > 1
  ) {
    return ['-', score];
  }
  return [score];
};

export const getThresholdName = (key: string) => {
  switch (key) {
    case 'totalThreshold':
      return 'Total';
    case 'totalOffset':
      return 'Total';
    case 'HEALTH_AND_SAFETY':
      return 'Pillar: Health and Safety';
    case 'LABOUR_STANDARDS_AND_RIGHTS':
      return 'Pillar: Labour Standards';
    case 'BUSINESS_ETHICS':
      return 'Pillar: Business Ethics';
    case 'ENVIRONMENT':
      return 'Pillar: Environment';
    default:
      return '';
  }
};

const getQuestionnaireTypeForServiceActivity = ({
  locationType,
  ownLocationType
}: WorkplaceActivityProfile) =>
  locationType === 'OWN' && ownLocationType === 'NON_OFFICE' ? 'GP' : 'SP';

const getQuestionnaireTypeForLabourActivity = ({
  workersProviderType
}: WorkplaceActivityProfile) =>
  workersProviderType === 'RECRUITMENT_AGENCY' ? 'RA' : 'SP';

const getQuestionnaireTypeForGoodsActivity = () => 'GP';

const getQuestionnaireTypeForAgentActivity = () => 'SA';

const getQuestionnaireTypeByActivity = {
  GOODS: getQuestionnaireTypeForGoodsActivity,
  LABOUR: getQuestionnaireTypeForLabourActivity,
  SERVICE: getQuestionnaireTypeForServiceActivity,
  AGENT: getQuestionnaireTypeForAgentActivity
};

export const getQuestionnaireType = (workplace?: Workplace) => {
  const activityType = workplace?.activityProfile.activityType || '';

  if (activityType && workplace?.activityProfile) {
    return getQuestionnaireTypeByActivity[activityType]?.(
      workplace?.activityProfile
    );
  }
};
