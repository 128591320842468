import { QuestionnaireType } from 'api/saq/types';
import React from 'react';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import { Spinner } from '../../../atoms/spinner/Spinner';
import {
  Paper,
  Table,
  TableContainer
} from '@mui/material';
import { useThresholdAndOffsetsData } from '../../../api/saq/hooks/useThresholdAndOffsetsData/useThresholdAndOffsetsData';
import { ThresholdAndOffsetBody } from '../../../components/ThresholdAndOffsetTable/ThresholdAndOffsetBody';

export const ThresholdAndOffsets = () => {
  const { questionnaireType } = useQuestionnaireContext();
  const { thresholdAndOffsets, isLoading } = useThresholdAndOffsetsData(
    questionnaireType as QuestionnaireType
  );

  return (
    <>
      <QuestionnaireTypeSelector />
      {isLoading ? (
        <Spinner isSuspense />
      ) : (
        <TableContainer component={Paper} sx={{ width: 600, marginTop: 8, border: '2px solid' }}>
          <Table stickyHeader size="small">
            <ThresholdAndOffsetBody thresholdAndOffsetData={thresholdAndOffsets}/>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
