import React, { Fragment } from 'react';
import { TableCell, TableRow} from '@mui/material';
import {getThresholdName} from "../../api/saq/utils";
import {TableHeadCell} from "../../screens/saq/SaqRisksTable.styles";
import {ThresholdAndOffsetRowData} from "./ThresholdAndOffsetBody";


export const ThresholdAndOffsetRow = ({header, data,}:
                                      {header: string, data?: ThresholdAndOffsetRowData}) => {
    return (
        <Fragment>
            <TableRow key={`row-header-thresholds`}>
                <TableHeadCell key={`header-thresholds`} colSpan={2}> {header} </TableHeadCell>
            </TableRow>
            {
                data?(
                    Object.entries(data).map(
                        ([key, value]) => (
                            <TableRow key={`row-thresholds-${key}`}>
                                <TableCell key={`question-thresholds-${key}`} >
                                    {getThresholdName(key)}
                                </TableCell>
                                <TableCell key={`question-thresholds-${key}-value`} >
                                    {value}
                                </TableCell>
                            </TableRow>
                        )
                    )):( <></>)
            }
        </Fragment>
    );
};