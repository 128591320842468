export type Link = {
  rel: string
  href: string
}

export type Linkable = {
  links: Record<string, Link>
}

export type AnyListResponse<T> = {
  results: T[]
} & Linkable


export enum CommonLinkRelationships {
  Self = 'SELF'
}

export type UserFeatureResponse = {
  sedexCode: string;
  features: FeatureToggle[];
};

export type FeatureToggle = {
  name: string;
  enabled: boolean;
  type?: string;
};

export type UserFeatureMutation = {
  sedexCode: string;
  features: FeatureToggle[];
};

export type AddFeature = {
  name: string;
  description: string;
  default?: boolean;
};

export type Feature = {
  id: string;
  name: string;
  description: string;
  default: boolean;
  createdTime: string;
} & Linkable;

export type UserFeature = {
  id: string;
  userCode: string;
  feature: FeatureToggle;
  createdTime: string;
} & Linkable;

export type OrgFeature = {
  id: string;
  orgCode: string;
  roleName?: string;
  feature: FeatureToggle;
  createdTime: string;
} & Linkable;

export type RoleFeature = {
  id: string;
  roleName?: string;
  memberTypes: string[];
  feature: FeatureToggle;
  createdTime: string;
} & Linkable;

export type FeatureResponse = AnyListResponse<Feature>;

export type DefaultFeatureToggle = {
  featureId: string;
  default: boolean;
};

export enum FeatureLinkRelationships {
  userFeatures = 'USER_FEATURES',
  orgFeatures = 'ORG_FEATURES',
  roleFeatures = 'ROLE_FEATURES'
}

export enum UserFeatureIdentifiers {
  FeaturesByUser = 'FeaturesByUser',
  ToggleFeaturesForUser = 'ToggleFeaturesForUser',
  FeaturesList = 'FeaturesList',
  AnyLinkResource = 'AnyLinkResource',
  UpdateDefaultFeature = 'UpdateDefaultFeature',
  AddFeature = 'AddFeature',
  DeleteFeature = 'DeleteFeature'
}
