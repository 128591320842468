import {
  useAnswers,
  useCalculatedAnswers,
  useCalculatedQuestions,
  useQuestions,
  useSiteQuestionnaireStatus,
  useWorkplaceRiskScores,
  useWorkplaceProfile,
  useScore,
  useQuestionnaire
} from 'api/saq/hooks/queries';
import {
  getQuestionRiskFromRecord,
  sortingScoreQuestions,
  getQuestionnaireType
} from 'api/saq/utils';
import {
  ManagementControlsRiskData,
  SiteCharacteristicsRiskData
} from 'api/saq/types';

export const useSiteRisksScore = (
  siteCode: string,
  isAnswers: boolean = false
) => {
  const {
    data: currentScores,
    isLoading: isCurrentScoresLoading,
    isSuccess: isSuccessWorkplaceRiskScores
  } = useWorkplaceRiskScores(siteCode);

  const { data } = useWorkplaceProfile(siteCode);
  const questionnaireType = getQuestionnaireType(data) || '';

  const {
    data: siteQuestionnaireStatus,
    isLoading: isSiteQuestionnaireStatusLoading,
    isSuccess: isSuccessSiteQuestionnaireStatus
  } = useSiteQuestionnaireStatus(siteCode, questionnaireType);

  const questionnaireId = siteQuestionnaireStatus
    ? siteQuestionnaireStatus.siteQuestionnaire?.questionnaireId
    : '';

  const companySize =
    siteQuestionnaireStatus?.siteQuestionnaire?.properties?.companySize;

  const { data: scoreData } = useScore(questionnaireId, questionnaireType);

  const {
    data: allQuestions,
    isLoading: isQuestionLoading,
    isSuccess: isSuccessQuestions
  } = useQuestions(questionnaireId);

  const {
    data: calculatedQuestions,
    isLoading: isCalculatedQuestionsLoading,
    isSuccess: isSuccessCalculatedQuestions
  } = useCalculatedQuestions(
    questionnaireType,
    !!siteCode && !!questionnaireType
  );

  const {
    data: allAnswers,
    isLoading: isAllAnswersLoading,
    isSuccess: isSuccessAllAnswers
  } = useAnswers(
    siteQuestionnaireStatus
      ? siteQuestionnaireStatus?.siteQuestionnaire?.id
      : '',
    isAnswers
  );

  const {
    data: calculatedAnswers,
    isLoading: isCalculatedAnswersLoading,
    isSuccess: isSuccessCalculatedAnswers
  } = useCalculatedAnswers(questionnaireType, siteCode, isAnswers);

  const questionsResults = allQuestions?.results ?? [];
  const calculatedQuestionsResults =
    calculatedQuestions?.results?.map((question) => ({
      ...question,
      possibleAnswers: question.options?.map((option) => ({
        id: option.key,
        label: option.label
      }))
    })) ?? [];

  const allQuestionsResults = [
    ...questionsResults,
    ...calculatedQuestionsResults
  ];

  const sortedSiteCharacteristicsRisk = sortingScoreQuestions(
    allQuestionsResults,
    currentScores?.scores.siteCharacteristicsRisk?.questionScores
  );

  const sortedManagementControlsRisk = sortingScoreQuestions(
    allQuestionsResults,
    currentScores?.scores.managementControlsRisk?.questionScores
  );

  const { data: sectionData } = useQuestionnaire(questionnaireType);

  const siteCharacteristicsQuestionRisk = getQuestionRiskFromRecord(
    sortedSiteCharacteristicsRisk,
    allQuestionsResults,
    allAnswers?.results ?? [],
    calculatedAnswers,
    scoreData?.results,
    companySize
  );

  const managementControlsQuestionRisk = getQuestionRiskFromRecord(
    sortedManagementControlsRisk,
    allQuestionsResults,
    allAnswers?.results ?? [],
    calculatedAnswers,
    scoreData?.results,
    companySize,
    sectionData
  );

  const siteCharacteristicsPillarsRisk = currentScores?.scores
    .siteCharacteristicsRisk?.pillars
    ? Object.entries(
        currentScores?.scores.siteCharacteristicsRisk?.pillars
      ).map(([key, value]) => ({
        label: key,
        score: value
      }))
    : [];

  const managementControlsSectionsRisk = currentScores?.scores
    .managementControlsRisk?.sectionScores
    ? Object.entries(
        currentScores?.scores.managementControlsRisk?.sectionScores
      ).map(([key, value]) => ({
        label: key,
        score: value
      }))
    : [];

  const siteCharacteristicsRisk: SiteCharacteristicsRiskData = {
    totalScore: currentScores?.scores.siteCharacteristicsRisk?.score || 0,
    questionScores: siteCharacteristicsQuestionRisk,
    pillars: siteCharacteristicsPillarsRisk
  };

  const managementControlsRisk: ManagementControlsRiskData = {
    totalScore: currentScores?.scores.managementControlsRisk?.score || 0,
    questionScores: managementControlsQuestionRisk,
    sectionScores: managementControlsSectionsRisk
  };

  return {
    siteCharacteristicsRisk,
    managementControlsRisk,
    isLoading:
      isCurrentScoresLoading ||
      isSiteQuestionnaireStatusLoading ||
      isQuestionLoading ||
      isAllAnswersLoading ||
      isCalculatedQuestionsLoading ||
      isCalculatedAnswersLoading,
    isSuccess:
      isSuccessWorkplaceRiskScores ||
      isSuccessSiteQuestionnaireStatus ||
      isSuccessQuestions ||
      isSuccessAllAnswers ||
      isSuccessCalculatedQuestions ||
      isSuccessCalculatedAnswers
  };
};
