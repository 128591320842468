import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { queryClient } from 'services/reactQuery/client';
import { AuthProvider } from 'services/auth/AuthProvider';
import HomeRouter from 'router/HomeRouter';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <HomeRouter />
        </BrowserRouter>
      </StyledEngineProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
