import React from 'react';
import { createContext, useContext } from 'react';
import { AuthService } from './AuthService';
import AuthStore from './AuthStore';
import environment from 'Environment';

type AuthContextType = {
  // The store handles the logic of storing state and authentication
  // information within the application.
  store: AuthStore;

  // The service provides the backing implementation for the back-end
  // service being used to handle auth.
  service: AuthService;
};

export const AuthContext = createContext<null | AuthContextType>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const context: AuthContextType = {
    store: new AuthStore(),
    service: new AuthService(environment.authorisationServiceUrl)
  };
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('AuthContext requires to be wrapped in an AuthProvider');
  }
  return context;
};
