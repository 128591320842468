import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@mui/material';
import { Feature, CommonLinkRelationships, Link } from 'api/userFeatures/types';
import { useUpdateDefaultFeature } from 'api/userFeatures/hooks/mutations';
import { useFeatureListQuery } from 'api/userFeatures/hooks/queries';
import { Spinner } from 'atoms/spinner/Spinner';
import { Layout } from 'layout/Layout';
import { useHistory } from 'react-router';

export const FeaturesList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: features, isLoading } = useFeatureListQuery();
  const { mutate: toggleDefaulFeature } = useUpdateDefaultFeature();

  const loadDetail = (link: Link) => {
    history.push({
      pathname: '/admin/features/detail',
      state: {
        link: link
      }
    });
  };

  const handleFeatureChange = (feature: Feature) => {
    toggleDefaulFeature({
      featureId: feature.id,
      default: !feature.default
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Grid item container justifyContent="space-between" sx={{ p: 2 }}>
        <Grid item>
          <Typography variant="h6">Feature List</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => history.push('/admin/features/add')}
          >
            Add feature
          </Button>
        </Grid>
      </Grid>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeading}>Feature Name</TableCell>
            <TableCell className={classes.tableHeading}>
              Feature Description
            </TableCell>
            <TableCell className={classes.tableHeading} align="center">
              Default Enabled
            </TableCell>
            <TableCell className={classes.tableHeading}>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features &&
            features.results &&
            features.results
              .sort((a, b) => (a.createdTime < b.createdTime ? 1 : -1))
              .map((feature: Feature) => (
                <TableRow
                  hover
                  key={feature.name}
                  className={
                    feature.name === 'Impersonation'
                      ? classes.disabled
                      : undefined
                  }
                >
                  <TableCell
                    component="th"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      loadDetail(feature.links[CommonLinkRelationships.Self])
                    }
                    scope="row"
                  >
                    {feature.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {feature.description}
                  </TableCell>
                  <TableCell component="th" align="center">
                    <Checkbox
                      color="secondary"
                      checked={feature.default}
                      disabled={feature.name === 'Impersonation'}
                      onChange={() => handleFeatureChange(feature)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(feature.createdTime).toISOString().split('T')[0]}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  }
}));
