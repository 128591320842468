import { useHistory } from 'react-router-dom';

export const useGetQueryParam = (param: string) => {
  const { location } = useHistory();

  const paramValue = (): string => {
    const paramValue = new URLSearchParams(location.search).get(param);
    return paramValue as string;
  };

  return { paramValue };
};
