import {DefaultFeatureToggle, UserFeatureIdentifiers} from "api/userFeatures/types";
import {userService} from "api/services";
import {userFeaturesUrls} from "api/userFeatures/resourcesUrl";
import {useMutation, useQueryClient} from "react-query";
import {AxiosError} from "axios";

const updateDefaultFeature = (payload: DefaultFeatureToggle) =>
    userService.put(userFeaturesUrls.updateFeature(payload.featureId), {
        default: payload.default
    });

export const useUpdateDefaultFeature = () => {
    const queryClient = useQueryClient();
    return useMutation<{}, AxiosError, DefaultFeatureToggle, {}>(
        UserFeatureIdentifiers.UpdateDefaultFeature,
        (payload) => updateDefaultFeature(payload),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(UserFeatureIdentifiers.FeaturesList)
        }
    );
};
