import React from 'react';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import { ScoresTable } from '../../../components/ScoresTable/ScoresTable';
import { Spinner } from '../../../atoms/spinner/Spinner';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { SubCategoryType } from 'api/saq/types';
import { useGetQuestionScoresData } from '../hooks/useQuestionScoresData';


export const SiteCharacteristic = () => {
  const { strategy: {
    [SubCategoryType.SiteCharacteristics]: { columnsNames, columnsMeta } }
  } = useQuestionnaireContext();
  const { questionnaire, isLoading } = useGetQuestionScoresData(
    SubCategoryType.SiteCharacteristics
  );

  const scColumnNames = columnsMeta
    .map((col) => col.name)
    .concat(columnsNames);

  return (
    <>
      <QuestionnaireTypeSelector />
      {isLoading ? (
        <Spinner isSuspense />
      ) : (
        <ScoresTable
          title="Site Characteristics - question scores"
          sections={questionnaire.sections}
          headerNames={scColumnNames}
          columnsMeta={columnsMeta}
        />
      )}
    </>
  );
};
