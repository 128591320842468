import { workplaceService } from 'api/services';
import { SaqIdentifiers, Workplace } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';
import type { Workplace as WorkplaceResult } from '../../../types';
const fetchWorkplaceProfile = (siteCode: string) =>
  workplaceService.get(Workplace.workplaceProfile(siteCode));

export const useWorkplaceProfile = (siteCode: string) =>
  useQuery<WorkplaceResult, Error, WorkplaceResult, string[]>(
    [SaqIdentifiers.workplace, siteCode],
    () => fetchWorkplaceProfile(siteCode),
    { enabled: !!siteCode }
  );
