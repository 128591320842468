import { useQuery } from 'react-query';
import { UserFeatureIdentifiers, Link } from 'api/userFeatures/types';
import { userService } from 'api/services';
import { userFeaturesUrls } from 'api/userFeatures/resourcesUrl';

export const useAnyLinkableQuery = <T>(link: Link | undefined) =>
useQuery<T>(
    [UserFeatureIdentifiers.AnyLinkResource, link],
    () => userService.get(userFeaturesUrls.anyPath(link as Link)),
    {
        enabled: !!link,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false
    }
);
