import {useMutation, useQueryClient} from "react-query";
import {UserFeatureIdentifiers, UserFeatureMutation, UserFeatureResponse} from "api/userFeatures/types";
import {userService} from "api/services";
import {userFeaturesUrls} from "api/userFeatures/resourcesUrl";

export const useToggleFeatureForUserMutation = (
    userCode: string | undefined
) => {
    const queryClient = useQueryClient();

    return useMutation(
        UserFeatureIdentifiers.ToggleFeaturesForUser,
        async (payload: UserFeatureMutation) => {
            const response: UserFeatureResponse = await userService.post(
                userFeaturesUrls.toggleFeaturesForUser,
                payload
            );
            return response;
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [UserFeatureIdentifiers.FeaturesByUser, userCode],
                    data
                );
            },
            onError: (error) => {
                console.error(error);
                alert('Unable to toggle feature');
            }
        }
    );
};
