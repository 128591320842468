import { Table, TableContainer } from '@mui/material';
import { ColumnMeta, QScore, Section } from 'api/saq/types';
import { Layout } from 'layout/Layout';
import React from 'react';
import { ScoresHeader } from './ScoresHeader';
import { ScoresBody } from './ScoresBody';

export type ScoresTableProps = {
  title: string;
  headerNames: string[];
  columnsMeta: ColumnMeta[];
  sections: Section<QScore>[];
};

export const ScoresTable = ({
  title,
  headerNames,
  columnsMeta,
  sections
}: ScoresTableProps) => {
  return (
    <Layout title={title}>
      <TableContainer>
        <Table stickyHeader size={'small'}>
          <ScoresHeader headerNames={headerNames} />
          <ScoresBody sections={sections} columnsMeta={columnsMeta} />
        </Table>
      </TableContainer>
    </Layout>
  );
};
