import { QScore, ScoreUpdate } from 'api/saq/types';
import { scoringService } from 'api/services';
import { SaqIdentifiers, ScoringUrls } from 'api/saq/resourcesUrl';
import { useMutation } from 'react-query';
import {
convertToPayload
} from './utils';

const updateScores = (payload: ScoreUpdate) =>
  scoringService.put(ScoringUrls.updateScores(payload.scoreId), payload.scores);

export const useUpdateQuestionScores = () => {
  return useMutation(
    SaqIdentifiers.updateScores,
    (questionScore: QScore) => updateScores(convertToPayload(questionScore)),
    {
      onError: () => {
        alert('Unable to update scores');
      }
    }
  );
};
