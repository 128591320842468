import { ScoreResponse } from 'api/saq/types';
import { scoringService } from 'api/services';
import { SaqIdentifiers, ScoringUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';

const fetchScore = (questionnaireType: string): Promise<ScoreResponse> =>
  scoringService.get(ScoringUrls.score(questionnaireType));

export const useScore = (questionnaireId: string, questionnaireType: string) =>
  useQuery<ScoreResponse, Error, ScoreResponse, string[]>(
    [SaqIdentifiers.score, questionnaireId, questionnaireType],
    () => fetchScore(questionnaireType),
    {
      enabled: !!questionnaireId && !!questionnaireType
    }
  );
