import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserFeature, AnyListResponse, Link } from 'api/userFeatures/types';
import { useAnyLinkableQuery } from 'api/userFeatures/hooks/queries';

type UserFeatureTableProps = {
  userFeaturesLink: Link;
};

export const UserFeatureTable: React.FC<UserFeatureTableProps> = ({
  userFeaturesLink
}) => {
  const classes = useStyles();
  const { data: userFeatures } =
    useAnyLinkableQuery<AnyListResponse<UserFeature>>(userFeaturesLink);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeading}>User Code</TableCell>
          <TableCell className={classes.tableHeading}>Enabled</TableCell>
          <TableCell className={classes.tableHeading}>Created</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userFeatures &&
          userFeatures.results &&
          userFeatures.results.map((userFeature: UserFeature) => (
            <TableRow>
              <TableCell component="th" scope="row">
                {userFeature.userCode}
              </TableCell>
              <TableCell component="th" scope="row">
                {'Enabled:  ' + userFeature.feature.enabled}
              </TableCell>

              <TableCell component="th" scope="row">
                {userFeature.createdTime}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  }
}));
