import Environment from 'Environment';
import { PATHS } from '../../consts/paths';

export class AuthService {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getAuthenticateUrl(state: string) {
    return `${this.baseUrl}/authenticate?state=${state}&callback=${window.location.origin}${PATHS.LoginCallback}&application=${Environment.gravitteeApplicationId}`;
  }

  getLogOutUrl(url: string) {
    return `${this.baseUrl}/logout?target=${url}`;
  }
}
