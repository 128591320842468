import { useAuthContext } from './AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';
import base64url from 'base64url';

export const useAuth = () => {
  const { store, service } = useAuthContext();

  const isAuthed = store.isAuthenticated();

  const redirectToLogIn = useCallback(
    (state: string) => {
      const url = service.getAuthenticateUrl(state);
      window.location.assign(url);
    },
    [service]
  );

  const createState = (url?: string): string => {
    const uuid = uuidv4();
    if (url) {
      return `${uuid}.${base64url.encode(url)}`;
    }
    return uuid;
  };

  const enforceSignIn = () => {
    const state = createState(window.location.href);

    // Set the state so that it can be retrieved and checked on redirect, this
    // prevents certain cross-site request forgery attacks. It also includes the
    // return URL to bring the user back to the relevant page from the callback.
    store.setState(state);

    redirectToLogIn(state);
  };

  const authenticateFromCallback = (state: string, token: string) => {
    store.authenticateFromCallback(state, token);

    // If the state includes a period it is a state containing a redirect
    // URL. This is typically used in the enforce sign in method where the
    // user is redirected away from the app to the login page.
    if (state.includes('.')) {
      const encoded = state.split('.')[1];
      const url = base64url.decode(encoded);
      window.location.assign(url);
    }
  };

  const signOut = useCallback(() => {
    store.logout();
  }, [store]);

  return {
    signOut,
    isAuthed,
    enforceSignIn,
    authenticateFromCallback
  };
};
