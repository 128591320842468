import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type SpinnerProps = {
  isSuspense?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({ isSuspense }) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={isSuspense ? classes.absoluteCentre : classes.margin}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(4)
  },
  absoluteCentre: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));
