import {QuestionResponse} from "api/saq/types";
import {questionnaireService} from "api/services";
import {SaqIdentifiers, SaqUrls} from "api/saq/resourcesUrl";
import {useQuery} from "react-query";

const fetchQuestions = ({
    queryKey
}: {
    queryKey: string[];
}): Promise<QuestionResponse> => {
    const [, questionnaireId] = queryKey;
    return questionnaireService.get(SaqUrls.question(questionnaireId));
};

export const useQuestions = (questionnaireId: string) =>
    useQuery<QuestionResponse, Error, QuestionResponse, string[]>(
        [SaqIdentifiers.question, questionnaireId],
        fetchQuestions,
        { enabled: !!questionnaireId }
    );
