import React, { createContext, FC, Fragment, useContext, useMemo, useState } from 'react';
import { getStrategy } from '../utils/strategy';
import { CoreSaqType, QuestionnaireType } from '../../../api/saq/types';

export type QuestionnaireProps = {
  questionnaireType: QuestionnaireType;
  setQuestionnaireType: React.Dispatch<React.SetStateAction<string>>;
  strategy: ReturnType<typeof getStrategy>;
};

const initialState: QuestionnaireType = CoreSaqType.GP;

const QuestionnaireContext = createContext<QuestionnaireProps | null>(null);

export const QuestionnaireProvider: FC = ({ children }) => {
  const [questionnaireType, setQuestionnaireType] = useState(initialState);

  const value = useMemo(
    () => ({
      questionnaireType,
      setQuestionnaireType,
      strategy: getStrategy({ questionnaireType })
    }),
    [questionnaireType]
  );

  return (
    <QuestionnaireContext.Provider value={value as QuestionnaireProps}>
      <Fragment key={questionnaireType}>{children}</Fragment>
    </QuestionnaireContext.Provider>
  );
};

export const useQuestionnaireContext = () => {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    throw new Error(
      'useQuestionnaireContext must be used within AdminQuestionnaireProvider'
    );
  }

  return context;
};
