import { AnswerResponse } from 'api/saq/types';
import { questionnaireService } from 'api/services';
import { SaqIdentifiers, SaqUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';

const fetchAnswers = ({
  queryKey
}: {
  queryKey: string[];
}): Promise<AnswerResponse> => {
  const [, siteQuestionnaireId] = queryKey;
  return questionnaireService.get(SaqUrls.answers(siteQuestionnaireId));
};

export const useAnswers = (siteQuestionnaireId: string, isAnswers: boolean) =>
  useQuery<AnswerResponse, Error, AnswerResponse, string[]>(
    [SaqIdentifiers.answers, siteQuestionnaireId],
    fetchAnswers,
    { enabled: !!siteQuestionnaireId && isAnswers }
  );
