import React, { useEffect } from 'react';
import { useGetQueryParam } from 'utils/commonHooks';
import { useAuth } from './useAuth';

export const AuthCallback: React.FC = () => {
  const stateParam = useGetQueryParam('state');
  const tokenParam = useGetQueryParam('token');
  const { authenticateFromCallback } = useAuth();

  useEffect(() => {
    const state = stateParam.paramValue();
    const token = tokenParam.paramValue();
    if (!state) {
      throw new Error('State parameter missing');
    }
    if (!token) {
      throw new Error('Token parameter missing');
    }
    authenticateFromCallback(state, token);
  }, [authenticateFromCallback, stateParam, tokenParam]);

  return <></>;
};
