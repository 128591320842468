import { Link, Button, Divider } from '@mui/material';
import { FileViewAnswerValue } from 'api/saq/types';
import { ArrowDownward } from '@material-ui/icons';
import { useDownloadFile } from 'api/saq/hooks/useDownloadFile/useDownloadFile';
import { v4 as uidv4 } from 'uuid';

type ListAnswersProps = {
  listData: string[] | FileViewAnswerValue[];
  isAnswerScoreColumn: boolean;
};

export const ListAnswers: React.FC<ListAnswersProps> = ({
  listData,
  isAnswerScoreColumn
}) => {
  const { download } = useDownloadFile();
  const lengthOfData = listData.length;
  return (
    <>
      {listData.map((value, index) => {
        const key = uidv4();
        if (value === undefined)
          return (
            <div key={key}>0{index !== lengthOfData - 1 && <Divider />}</div>
          );
        if (typeof value === 'string' || typeof value === 'number') {
          return (
            <div key={key}>
              <>{value}</>
              {index !== lengthOfData - 1 && <Divider />}
            </div>
          );
        } else if (value?.fileLink) {
          return (
            <div key={key}>
              {isAnswerScoreColumn ? (
                index === 0 && 1
              ) : (
                <Link href={value.fileLink} underline="hover" target={'_blank'}>
                  {value.fileLink}
                </Link>
              )}

              {index !== lengthOfData - 1 && !!!isAnswerScoreColumn && (
                <Divider />
              )}
            </div>
          );
        } else if (value?.fileName) {
          return (
            <div key={key}>
              {isAnswerScoreColumn ? (
                index === 0 && 1
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<ArrowDownward />}
                  onClick={() =>
                    download(value.fileName ?? '', value.fileDownloadLink ?? '')
                  }
                >
                  {value.fileName}
                </Button>
              )}
              {index !== lengthOfData - 1 && !!!isAnswerScoreColumn && (
                <Divider />
              )}
            </div>
          );
        }

        return null;
      })}
    </>
  );
};
