import { scoringService } from 'api/services';
import { SaqIdentifiers, SaqUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';
import { ThresholdAndOffsetsResponse } from '../../../types';

const fetchThresholdAndOffsets = () =>
  scoringService.get(SaqUrls.thresholdAndOffsets());

export const useThresholdAndOffsets = () =>
  useQuery<
    ThresholdAndOffsetsResponse,
    Error,
    ThresholdAndOffsetsResponse,
    string[]
  >([SaqIdentifiers.thresholdAndOffsets], () => fetchThresholdAndOffsets());
