import localStorageClass, {
  LocalStorageItems,
  TRUE_VALUE
} from 'utils/localStorage';

export type BearerTokenProvider = {
  getToken(): string | null;
};

export default class AuthStore implements BearerTokenProvider {
  isAuthenticated() {
    const status = localStorageClass.getItem(
      LocalStorageItems.AuthenticationStatusKey
    );
    return status != null && status === TRUE_VALUE;
  }

  logout() {
    localStorageClass.clear();
  }

  setState(state: string) {
    localStorageClass.setItem(LocalStorageItems.StateKey, state);
  }

  authenticateFromCallback(state: string, token: string) {
    console.log('AUTHENTICATE FROM CALLBACK', state, token);
    const currentState = localStorageClass.getItem(
      LocalStorageItems.StateKey
    ) as string;
    localStorageClass.removeItem(LocalStorageItems.StateKey);

    if (currentState !== state) {
      throw new Error('State does not match, preventing CSRF');
    }

    localStorageClass.setItem(
      LocalStorageItems.AuthenticationStatusKey,
      TRUE_VALUE
    );
    localStorageClass.setItem(LocalStorageItems.TokenKey, token);
  }

  getToken() {
    return localStorageClass.getItem(LocalStorageItems.TokenKey);
  }
}
