import { TableHead, TableRow } from '@mui/material';
import { TableHeadCell } from '../../screens/saq/SaqRisksTable.styles';

export const ScoresHeader = ({ headerNames }: { headerNames: string[] }) => {
  return (
    <TableHead>
      <TableRow>
        {headerNames.map((name) => (
          <TableHeadCell key={`header-${name}`}>{name}</TableHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
