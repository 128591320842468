import { questionnaireService } from 'api/services';
import { SaqIdentifiers, SaqUrls } from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';
import {SectionsResponse} from "../../../types";

const fetchQuestionnaire = (questionnaireType: string) : Promise<SectionsResponse> =>
  questionnaireService.get(SaqUrls.questionnaire(questionnaireType));

export const useQuestionnaire = (questionnaireType: string) =>
  useQuery([SaqIdentifiers.questionnaire, questionnaireType], () =>
    fetchQuestionnaire(questionnaireType),{enabled: !!questionnaireType}
  );
