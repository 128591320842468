import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'styles/theme';
import { Sidebar } from 'atoms/Sidebar/sidebar';
import { defaults } from 'styles/defaults';
import AdminRouter from 'router/AdminRouter';

export const AdminLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Sidebar />

      <div className={classes.content}>
        <AdminRouter />
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: defaults.sidebarWidth,
    padding: theme.spacing(4)
  },
  content: {
    flexGrow: 1,
    marginLeft: defaults.sidebarWidth,
    padding: theme.spacing(3)
  }
}));
