import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
  StyledTableRow,
  TableHeadCell
} from 'screens/saq/SaqRisksTable.styles';
import { Layout } from 'layout/Layout';
import { QuestionRiskData } from 'api/saq/types';
import { ListAnswers } from 'components/ListAnswers/ListAnswers';

type WorkplaceRiskTableProps = {
  title: string;
  riskType: string;
  data: QuestionRiskData[];
};

export const WorkplaceRiskAnswersTable: React.FC<WorkplaceRiskTableProps> = ({
  title,
  riskType,
  data
}) => {
  return (
    <Layout title={title} isLoading={false}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              {riskType === 'SC' ? 'Pillar' : 'Section'}
            </TableHeadCell>
            <TableHeadCell>Question</TableHeadCell>
            <TableHeadCell>Answer selected</TableHeadCell>
            <TableHeadCell>Answer Score</TableHeadCell>
            <TableHeadCell>Question Score</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: QuestionRiskData, index: number) => (
            <StyledTableRow key={`row-${index}`} showDelimiter>
              <TableCell size="small" width="10%">
                {riskType === 'SC' ? (
                  <ListAnswers
                    listData={row?.pillars.length ? row?.pillars : row?.tags}
                    isAnswerScoreColumn={false}
                  />
                ) : (
                  row?.sectionName
                )}
              </TableCell>
              <TableCell size="small" width="30%">
                {row.label}
              </TableCell>
              <TableCell size="small" width="50%">
                <ListAnswers
                  listData={row?.answer}
                  isAnswerScoreColumn={false}
                />
              </TableCell>
              <TableCell size="small" width="5%">
                <ListAnswers listData={row?.answerScore} isAnswerScoreColumn />
              </TableCell>
              <TableCell size="small" width="5%">
                {row?.score}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Layout>
  );
};
