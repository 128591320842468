import { useQuery } from 'react-query';
import { SiteQuestionnaireStatusResult } from 'api/saq/types';
import { SaqIdentifiers, SaqUrls } from 'api/saq/resourcesUrl';
import { questionnaireService } from 'api/services';

const fetchSiteQuestionnaireStatus = ({
  queryKey
}: {
  queryKey: string[];
}): Promise<SiteQuestionnaireStatusResult> => {
  const [, siteCode, questionnaireType] = queryKey;
  return questionnaireService.get(
    SaqUrls.siteQuestionnaireStatus(siteCode, questionnaireType)
  );
};

export const useSiteQuestionnaireStatus = (
  siteCode: string,
  questionnaireType: string
) =>
  useQuery<
    SiteQuestionnaireStatusResult,
    Error,
    SiteQuestionnaireStatusResult,
    string[]
  >(
    [SaqIdentifiers.siteQuestionnaireStatus, siteCode, questionnaireType],
    fetchSiteQuestionnaireStatus,
    { enabled: !!siteCode && !!questionnaireType }
  );
