import React from 'react';
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svgs/logo.svg';
import { defaults } from 'styles/defaults';
import { FeaturesDashboardRoutes, SaqRoutes } from 'router/AdminRouter';
import { RouteConfig } from 'router/RouteConfig';
import { useAuth } from './../../services/auth/useAuth';

export const Sidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  const activeRoute = (routeName: any) => {
    return history.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const logout = () => {
    auth.signOut();
    history.push('/');
  };

  const renderSection = (routes: Array<RouteConfig>, sectionName: string) => {
    return (
      <List
        subheader={<ListSubheader component="div">{sectionName}</ListSubheader>}
      >
        {routes.map((prop, key) => {
          return (
            <Link to={prop.path} style={{ textDecoration: 'none' }} key={key}>
              <MenuItem dense selected={activeRoute(prop.path)}>
                <ListItemIcon>
                  <prop.icon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  classes={{ primary: classes.listItemText }}
                />
              </MenuItem>
            </Link>
          );
        })}
      </List>
    );
  };

  return (
    <Paper className={classes.container} elevation={0}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Logo />
        <Button type="submit" name="logout" onClick={logout} color="primary">
          Log out
        </Button>
        <Divider />
        {renderSection(FeaturesDashboardRoutes, 'Features')}
        <Divider />
        {renderSection(SaqRoutes, 'SAQ')}
        <Divider />
      </Drawer>
    </Paper>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: defaults.sidebarWidth,
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 10
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: 8
  },
  listItemText: {
    fontSize: 12,
    color: 'black'
  }
}));
