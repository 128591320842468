import { QuestionnaireType } from 'api/saq/types';
import { useThresholdAndOffsets } from '../queries/useThresholdAndOffsets/useThresholdAndOffsets';

export const useThresholdAndOffsetsData = (
  questionnaireType: QuestionnaireType
) => {
  const {
    data: thresholdAndOffsetsData,
    isLoading: isThresholdAndOffsetsLoading
  } = useThresholdAndOffsets();

  const thresholdAndOffsetsByType = thresholdAndOffsetsData?.results.find(
    (thresholdAndOffsetsResult) =>
      thresholdAndOffsetsResult.questionnaire ===
      questionnaireType.toUpperCase()
  );
  const thresholdAndOffsets = thresholdAndOffsetsByType
    ? {
        thresholdsData: {
          totalThreshold: thresholdAndOffsetsByType.thresholds
            .totalThreshold as number,
          ...thresholdAndOffsetsByType.thresholds.pillarThresholds
        },
        offsetsData: {
          totalOffset: thresholdAndOffsetsByType.thresholds
            .totalOffset as number,
          ...thresholdAndOffsetsByType.thresholds.pillarOffsets
        }
      }
    : {};

  return {
    thresholdAndOffsets,
    isLoading: isThresholdAndOffsetsLoading
  };
};
