import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChangeEvent, useCallback, useState } from 'react';
import { useFeatureQuery } from 'api/userFeatures/hooks/queries';
import { FeatureToggle } from 'api/userFeatures/types';
import { useToggleFeatureForUserMutation } from 'api/userFeatures/hooks/mutations';
import { FeatureTable } from 'components/FeatureTable/featureTable';

export const Features = () => {
  const classes = useStyles();

  const [userCode, setUserCode] = useState<string | undefined>();
  const [rawUserCode, setRawUserCode] = useState<string>('');
  const { data, isLoading, refetch } = useFeatureQuery(userCode);
  const { mutate } = useToggleFeatureForUserMutation(userCode);

  const features = data?.features ?? [];

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    setRawUserCode(event.target.value);

  const performSearch = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (rawUserCode === userCode) {
      refetch();
    } else {
      setUserCode(rawUserCode);
    }
  };

  const toggleFeatureForUser = useCallback(
    (featureToggle: FeatureToggle) => {
      if (userCode) {
        mutate({
          sedexCode: userCode,
          features: [featureToggle]
        });
      }
    },
    [mutate, userCode]
  );

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Feature Manager</Typography>
      <form onSubmit={performSearch}>
        <Grid className={classes.inputForm}>
          <TextField
            label="User Code"
            onChange={onChange}
            value={rawUserCode}
          />
          <Button type="submit" className={classes.button} variant="contained">
            Search
          </Button>
        </Grid>
      </form>
      {features.length > 0 && (
        <FeatureTable
          features={features}
          onFeatureToggle={toggleFeatureForUser}
        />
      )}
      {isLoading && <CircularProgress />}
    </Paper>
  );
};

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  inputForm: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    marginLeft: theme.spacing(2)
  }
}));
