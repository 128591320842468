import { useQuery } from 'react-query';
import { FeatureResponse, UserFeatureIdentifiers } from 'api/userFeatures/types';
import { userService } from 'api/services';
import { userFeaturesUrls } from 'api/userFeatures/resourcesUrl';

export const useFeatureListQuery = () =>
    useQuery<FeatureResponse>(
        [UserFeatureIdentifiers.FeaturesList],
        () => userService.get(userFeaturesUrls.features),
        {
            enabled: true,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchInterval: false
        }
    );
