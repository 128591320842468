import axios, { AxiosRequestConfig } from 'axios';
import { BearerTokenProvider } from 'services/auth/AuthStore';

export type TransportLayer<T> = {
  get: (resource: string, config?: AxiosRequestConfig) => Promise<T>;
  post: (resource: string, payload: any) => Promise<T>;
  put: (resource: string, payload: any) => Promise<T>;
  delete: (resource: string) => Promise<T>;
};

type Response<T> = {
  data: T;
};

class AxiosTransportLayer<T> implements TransportLayer<T> {
  private baseUrl: string;
  private headers?: Map<string, string>;
  private bearerTokenProvider?: BearerTokenProvider;

  constructor(
    baseUrl: string,
    bearerTokenProvider: BearerTokenProvider | null = null,
    headers?: Map<string, string>
  ) {
    this.baseUrl = baseUrl;
    this.headers = headers;
    if (bearerTokenProvider) {
      this.bearerTokenProvider = bearerTokenProvider;
      this.addAuthInterceptor();
    }
  }

  getAuthHeaders(): {
    token: string | null;
  } | null {
    const accessToken = this.bearerTokenProvider?.getToken();
    return accessToken ? { token: `Bearer ${accessToken}` } : null;
  }

  addAuthInterceptor() {
    axios.interceptors.request.use((config) => {
      const authHeaders = this.getAuthHeaders();
      if (authHeaders?.token) {
        config.headers.Authorization = authHeaders.token;
      }
      return config;
    });
  }

  get(resource: string, config = {}): Promise<T> {
    return axios
      .get(this.baseUrl + resource, {...{ headers: this.headers }, ...config})
      .then((response: Response<T>) => response.data);
  }

  post(resource: string, payload: any): Promise<T> {
    return axios
      .post(this.baseUrl + resource, payload, {
        headers: this.headers
      })
      .then((response) => response.data);
  }

  put(resource: string, payload: any): Promise<T> {
    return axios.put(this.baseUrl + resource, payload, {
      headers: this.headers
    });
  }

  delete(resource: string): Promise<T> {
    return axios.delete(this.baseUrl + resource, { headers: this.headers });
  }
}

export default AxiosTransportLayer;
