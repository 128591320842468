import { questionnaireWorkplaceEtlService } from 'api/services';
import {
  QuestionnaireWorkplaceEtlUrls,
  SaqIdentifiers
} from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';
import { CalculatedQuestionResponse } from 'api/saq/types';
import { AxiosError } from 'axios';

const getCalculatedQuestions = (questionnaireType: string) =>
  questionnaireWorkplaceEtlService.get(
    QuestionnaireWorkplaceEtlUrls.calculatedQuestions(questionnaireType)
  );

export const useCalculatedQuestions = (
  questionnaireType: string,
  isEnabled: boolean | string = true
) =>
  useQuery<CalculatedQuestionResponse, AxiosError, CalculatedQuestionResponse>(
    [SaqIdentifiers.calculatedQuestions, questionnaireType],
    () => getCalculatedQuestions(questionnaireType),
    {
      enabled: !!questionnaireType && !!isEnabled
    }
  );
