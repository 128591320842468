import { createMuiTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#ffffff'
    },
    primary: {
      main: '#009590'
    },
    secondary: {
      main: '#354670'
    },
    error: {
      main: '#F44336'
    },
    warning: {
      main: '#FFB400',
      light:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800',
      dark: '#4D0000'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#fff',
          backgroundColor: '#009590',
          '&:hover': {
            backgroundColor: '#00625F',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: '#009590'
            }
          }
        },
        outlined: {
          borderColor: '#009590',
          color: '#009590'
        },
        root: {
          textTransform: 'capitalize',
          '&$disabled': {
            backgroundColor: grey[500]
          }
        }
      }
    }
  }
});
