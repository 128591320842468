import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  StyledTableRow,
  TableHeadCell
} from '../../screens/saq/SaqRisksTable.styles';
import { Layout } from '../../layout/Layout';
import { RiskData } from '../../api/saq/types';

interface FeatureTableProps {
  title: string;
  data: RiskData[];
}

export const WorkplaceRiskTable: React.FC<FeatureTableProps> = ({
  title,
  data
}) => {
  return (
    <Layout title={title} isLoading={false}>
      <Table>
        <TableHead>
          <TableRow key={`heading`}>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Score</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row: RiskData) => (
              <StyledTableRow key={`score-${row.label}`} showDelimiter>
                <TableCell scope="row" size="small" key="label">
                  {row.label}
                </TableCell>
                <TableCell scope="row" size="small" key="value" width={200}>
                  {row.score}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </Layout>
  );
};
