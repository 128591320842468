import {AddFeature, Feature, FeatureResponse, UserFeatureIdentifiers} from "api/userFeatures/types";
import {userService} from "api/services";
import {userFeaturesUrls} from "api/userFeatures/resourcesUrl";
import {useMutation} from "react-query";
import {queryClient} from "services/reactQuery/client";

const addFeature = (addFeaturePayload: AddFeature): Promise<Feature> =>
    userService.post(userFeaturesUrls.addFeature, addFeaturePayload);

export const useAddFeature = () => {
    return useMutation(UserFeatureIdentifiers.AddFeature, addFeature, {
        onSuccess: (data) => {
            queryClient.setQueryData<FeatureResponse>(
                [UserFeatureIdentifiers.FeaturesList],
                (old) => {
                    const previousFeatures = old?.results || [];
                    return {
                        links: old?.links || {},
                        results: [data, ...previousFeatures]
                    };
                }
            );
        }
    });
};
