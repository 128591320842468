import React from 'react';
import {TableBody} from '@mui/material';
import { ThresholdDataByType} from "../../api/saq/types";
import {ThresholdAndOffsetRow} from "./ThresholdAndOffsetRow";

export type ThresholdAndOffsetRowData = {
    row?: Record<string, number>;
}

export const ThresholdAndOffsetBody = ({thresholdAndOffsetData}:{thresholdAndOffsetData: ThresholdDataByType;}) => {

     return (
        <TableBody>
            <ThresholdAndOffsetRow header="Thresholds" data={thresholdAndOffsetData.thresholdsData}/>
            <ThresholdAndOffsetRow header="Offsets" data={thresholdAndOffsetData.offsetsData}/>
        </TableBody>
    );
};