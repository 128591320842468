import { questionnaireWorkplaceEtlService } from 'api/services';
import {
  QuestionnaireWorkplaceEtlUrls,
  SaqIdentifiers
} from 'api/saq/resourcesUrl';
import { useQuery } from 'react-query';
import { CalculatedRiskAnswersResult } from 'api/saq/types';
import { AxiosError } from 'axios';

const fetchCalculatedAnswers = ({
  queryKey
}: {
  queryKey: string[];
}): Promise<CalculatedRiskAnswersResult> => {
  const [, questionnaireType, siteCode] = queryKey;
  return questionnaireWorkplaceEtlService.get(
    QuestionnaireWorkplaceEtlUrls.calculatedAnswers(questionnaireType, siteCode)
  );
};

export const useCalculatedAnswers = (
  questionnaireType: string,
  siteCode: string,
  isCalculatedAnswers: boolean = false
) =>
  useQuery<
    CalculatedRiskAnswersResult,
    AxiosError,
    CalculatedRiskAnswersResult,
    string[]
  >(
    [SaqIdentifiers.calculatedAnswers, questionnaireType, siteCode],
    fetchCalculatedAnswers,
    {
      enabled: !!siteCode && isCalculatedAnswers && !!questionnaireType
    }
  );
